<script setup lang="ts">
const { t } = useI18n();
const siteConfig = useSiteConfig();

useSeoMeta({
  description: t("meta.description"),
  ogDescription: t("meta.description"),
  ogImage: siteConfig.url + "/og-image.png",
  twitterCard: "summary_large_image",
  themeColor: "#1867C0",
});
</script>

<template>
  <!--  <Head>-->
  <!--    <VitePwaManifest />-->
  <!--  </Head>-->

  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

import { default as aboutVMHecu1BOSMeta } from "/builds/helmundwalter/homepage/_common/pages/about.vue?macro=true";
import { default as _91slug_936TlIu9iIQdMeta } from "/builds/helmundwalter/homepage/helmundwalter.de/pages/blog/[slug].vue?macro=true";
import { default as index74cadP0VtMMeta } from "/builds/helmundwalter/homepage/helmundwalter.de/pages/blog/index.vue?macro=true";
import { default as _91name_93hVufqUykBvMeta } from "/builds/helmundwalter/homepage/_common/pages/employees/[name].vue?macro=true";
import { default as indexiaacryv0w3Meta } from "/builds/helmundwalter/homepage/_common/pages/employees/index.vue?macro=true";
import { default as expertisetbdeY2Zl0RMeta } from "/builds/helmundwalter/homepage/helmundwalter.de/pages/expertise.vue?macro=true";
import { default as imprint747Ie0hiDFMeta } from "/builds/helmundwalter/homepage/_common/pages/imprint.vue?macro=true";
import { default as indexoqY4Dxzss7Meta } from "/builds/helmundwalter/homepage/helmundwalter.de/pages/index.vue?macro=true";
import { default as privacyWagMjnOrWzMeta } from "/builds/helmundwalter/homepage/_common/pages/privacy.vue?macro=true";
import { default as shopwarezGHRrPKJObMeta } from "/builds/helmundwalter/homepage/helmundwalter.de/pages/shopware.vue?macro=true";
import { default as sitemapA6Nd1ydypyMeta } from "/builds/helmundwalter/homepage/_common/pages/sitemap.vue?macro=true";
import { default as software_45development6h0riFnYDyMeta } from "/builds/helmundwalter/homepage/helmundwalter.de/pages/software-development.vue?macro=true";
import { default as _91slug_93F66kLxZgxiMeta } from "/builds/helmundwalter/homepage/helmundwalter.de/pages/technologies/[slug].vue?macro=true";
import { default as indexwVW789lqEjMeta } from "/builds/helmundwalter/homepage/helmundwalter.de/pages/technologies/index.vue?macro=true";
import { default as component_45stubw4F8sQ3PNxMeta } from "/builds/helmundwalter/homepage/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubw4F8sQ3PNx } from "/builds/helmundwalter/homepage/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about___de",
    path: "/ueber-uns",
    component: () => import("/builds/helmundwalter/homepage/_common/pages/about.vue")
  },
  {
    name: "about___en",
    path: "/en/about",
    component: () => import("/builds/helmundwalter/homepage/_common/pages/about.vue")
  },
  {
    name: "blog-slug___de",
    path: "/blog/:slug()",
    component: () => import("/builds/helmundwalter/homepage/helmundwalter.de/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___en",
    path: "/en/blog/:slug()",
    component: () => import("/builds/helmundwalter/homepage/helmundwalter.de/pages/blog/[slug].vue")
  },
  {
    name: "blog___de",
    path: "/blog",
    component: () => import("/builds/helmundwalter/homepage/helmundwalter.de/pages/blog/index.vue")
  },
  {
    name: "blog___en",
    path: "/en/blog",
    component: () => import("/builds/helmundwalter/homepage/helmundwalter.de/pages/blog/index.vue")
  },
  {
    name: "employees-name___de",
    path: "/mitarbeiter/:name()",
    component: () => import("/builds/helmundwalter/homepage/_common/pages/employees/[name].vue")
  },
  {
    name: "employees-name___en",
    path: "/en/employees/:name()",
    component: () => import("/builds/helmundwalter/homepage/_common/pages/employees/[name].vue")
  },
  {
    name: "employees___de",
    path: "/mitarbeiter",
    component: () => import("/builds/helmundwalter/homepage/_common/pages/employees/index.vue")
  },
  {
    name: "employees___en",
    path: "/en/employees",
    component: () => import("/builds/helmundwalter/homepage/_common/pages/employees/index.vue")
  },
  {
    name: "expertise___de",
    path: "/expertise",
    component: () => import("/builds/helmundwalter/homepage/helmundwalter.de/pages/expertise.vue")
  },
  {
    name: "expertise___en",
    path: "/en/expertise",
    component: () => import("/builds/helmundwalter/homepage/helmundwalter.de/pages/expertise.vue")
  },
  {
    name: "imprint___de",
    path: "/impressum",
    component: () => import("/builds/helmundwalter/homepage/_common/pages/imprint.vue")
  },
  {
    name: "imprint___en",
    path: "/en/imprint",
    component: () => import("/builds/helmundwalter/homepage/_common/pages/imprint.vue")
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/builds/helmundwalter/homepage/helmundwalter.de/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/builds/helmundwalter/homepage/helmundwalter.de/pages/index.vue")
  },
  {
    name: "privacy___de",
    path: "/datenschutz",
    component: () => import("/builds/helmundwalter/homepage/_common/pages/privacy.vue")
  },
  {
    name: "privacy___en",
    path: "/en/privacy",
    component: () => import("/builds/helmundwalter/homepage/_common/pages/privacy.vue")
  },
  {
    name: "shopware___de",
    path: "/shopware",
    component: () => import("/builds/helmundwalter/homepage/helmundwalter.de/pages/shopware.vue")
  },
  {
    name: "shopware___en",
    path: "/en/shopware",
    component: () => import("/builds/helmundwalter/homepage/helmundwalter.de/pages/shopware.vue")
  },
  {
    name: "sitemap___de",
    path: "/sitemap",
    component: () => import("/builds/helmundwalter/homepage/_common/pages/sitemap.vue")
  },
  {
    name: "sitemap___en",
    path: "/en/sitemap",
    component: () => import("/builds/helmundwalter/homepage/_common/pages/sitemap.vue")
  },
  {
    name: "software-development___de",
    path: "/software-entwicklung",
    component: () => import("/builds/helmundwalter/homepage/helmundwalter.de/pages/software-development.vue")
  },
  {
    name: "software-development___en",
    path: "/en/software-development",
    component: () => import("/builds/helmundwalter/homepage/helmundwalter.de/pages/software-development.vue")
  },
  {
    name: "technologies-slug___de",
    path: "/technologien/:slug()",
    component: () => import("/builds/helmundwalter/homepage/helmundwalter.de/pages/technologies/[slug].vue")
  },
  {
    name: "technologies-slug___en",
    path: "/en/technologies/:slug()",
    component: () => import("/builds/helmundwalter/homepage/helmundwalter.de/pages/technologies/[slug].vue")
  },
  {
    name: "technologies___de",
    path: "/technologien",
    component: () => import("/builds/helmundwalter/homepage/helmundwalter.de/pages/technologies/index.vue")
  },
  {
    name: "technologies___en",
    path: "/en/technologies",
    component: () => import("/builds/helmundwalter/homepage/helmundwalter.de/pages/technologies/index.vue")
  },
  {
    name: component_45stubw4F8sQ3PNxMeta?.name,
    path: "/blog/our-loved-php-cpp-java-scala-dhtmlx-infinidb-zend",
    component: component_45stubw4F8sQ3PNx
  },
  {
    name: component_45stubw4F8sQ3PNxMeta?.name,
    path: "/blog/basho-riak-documentation-mirror",
    component: component_45stubw4F8sQ3PNx
  },
  {
    name: component_45stubw4F8sQ3PNxMeta?.name,
    path: "/blog/next-gen-backup-with-btrfs-snapshots-for-root-fs-and-databases",
    component: component_45stubw4F8sQ3PNx
  },
  {
    name: component_45stubw4F8sQ3PNxMeta?.name,
    path: "/blog/next-gen-backup-with-btrfs-snapshots-for-mysqlmariadb-and-root-filesystem",
    component: component_45stubw4F8sQ3PNx
  },
  {
    name: component_45stubw4F8sQ3PNxMeta?.name,
    path: "/blog/raid5-vs-raid10-performance-benchmark-mdadm",
    component: component_45stubw4F8sQ3PNx
  },
  {
    name: component_45stubw4F8sQ3PNxMeta?.name,
    path: "/blog/why-we-dont-use-samsung-nvme-pro-ssds-anymore",
    component: component_45stubw4F8sQ3PNx
  },
  {
    name: component_45stubw4F8sQ3PNxMeta?.name,
    path: "/blog/proper-chroot-in-rescue-mode-using-arch-chroot",
    component: component_45stubw4F8sQ3PNx
  },
  {
    name: component_45stubw4F8sQ3PNxMeta?.name,
    path: "/blog/btrfs-finding-and-fixing-highly-fragmented-files",
    component: component_45stubw4F8sQ3PNx
  },
  {
    name: component_45stubw4F8sQ3PNxMeta?.name,
    path: "/blog/shopware-5-parallel-thumbnail-generation",
    component: component_45stubw4F8sQ3PNx
  },
  {
    name: component_45stubw4F8sQ3PNxMeta?.name,
    path: "/blog/how-to-use-different-dns-servers-on-specific-domains",
    component: component_45stubw4F8sQ3PNx
  },
  {
    name: component_45stubw4F8sQ3PNxMeta?.name,
    path: "/blog/riak-compact-eleveldb-tombstones-and-reclaim-disk-space",
    component: component_45stubw4F8sQ3PNx
  },
  {
    name: component_45stubw4F8sQ3PNxMeta?.name,
    path: "/wp-content/uploads/2011/11/bachelor_thesis_bernd_michael_helm_-_Konzeption_einer_Hochverfuegbarkeitsloesung_fuer_LAMP-Systeme_mit_Open-Source-Software_und_standard-hardware.pdf",
    component: component_45stubw4F8sQ3PNx
  },
  {
    name: component_45stubw4F8sQ3PNxMeta?.name,
    path: "/wp-content/uploads/2011/11/Walter_Thesis_Druckversion5.pdf",
    component: component_45stubw4F8sQ3PNx
  }
]
import revive_payload_client_Frd8gASYWa from "/builds/helmundwalter/homepage/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_i18n_764ZNz3BM3 from "/builds/helmundwalter/homepage/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-i18n.js";
import vuetify_icons_LGovp4zj80 from "/builds/helmundwalter/homepage/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_3Bi0E2Ktsf from "/builds/helmundwalter/homepage/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CrWB4n4PyO from "/builds/helmundwalter/homepage/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_l19uMh4P89 from "/builds/helmundwalter/homepage/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_T5aD6w6QZh from "/builds/helmundwalter/homepage/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_JA9PnUIOcc from "/builds/helmundwalter/homepage/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_yxgjJ9XxnV from "/builds/helmundwalter/homepage/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_kCUITUWXUN from "/builds/helmundwalter/homepage/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/helmundwalter/homepage/helmundwalter.de/.nuxt/components.plugin.mjs";
import prefetch_client_5ZRYOjuei0 from "/builds/helmundwalter/homepage/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_UWUxmn85Fk from "/builds/helmundwalter/homepage/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/builds/helmundwalter/homepage/helmundwalter.de/.nuxt/sentry-client-config.mjs";
import vuetify_no_client_hints_Wluz5RTRGi from "/builds/helmundwalter/homepage/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import titles_GTyiibPwXi from "/builds/helmundwalter/homepage/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_9OPbuCmyBj from "/builds/helmundwalter/homepage/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_ZWq9TlLEe2 from "/builds/helmundwalter/homepage/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_t3yL13D7A3 from "/builds/helmundwalter/homepage/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_0pL4f4Rozx from "/builds/helmundwalter/homepage/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import switch_locale_path_ssr_7CGgCC9Jj0 from "/builds/helmundwalter/homepage/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_DqO8wZLCYH from "/builds/helmundwalter/homepage/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import mail_mp0axCtSWq from "/builds/helmundwalter/homepage/_common/plugins/mail.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/builds/helmundwalter/homepage/helmundwalter.de/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  revive_payload_client_Frd8gASYWa,
  vuetify_i18n_764ZNz3BM3,
  vuetify_icons_LGovp4zj80,
  unhead_3Bi0E2Ktsf,
  router_CrWB4n4PyO,
  _0_siteConfig_l19uMh4P89,
  payload_client_T5aD6w6QZh,
  navigation_repaint_client_JA9PnUIOcc,
  check_outdated_build_client_yxgjJ9XxnV,
  chunk_reload_client_kCUITUWXUN,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5ZRYOjuei0,
  sentry_client_UWUxmn85Fk,
  sentry_client_config_o34nk2sJbg,
  vuetify_no_client_hints_Wluz5RTRGi,
  titles_GTyiibPwXi,
  defaultsWaitI18n_9OPbuCmyBj,
  siteConfig_ZWq9TlLEe2,
  inferSeoMetaPlugin_t3yL13D7A3,
  plugin_0pL4f4Rozx,
  switch_locale_path_ssr_7CGgCC9Jj0,
  i18n_DqO8wZLCYH,
  mail_mp0axCtSWq,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]